import React, {
  memo,
  useCallback,
  useState
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Transition } from 'react-transition-group';

import style from './Accordion.module.scss';

const onEnter = (elem) => {
  elem.style.height = '0px'; // eslint-disable-line no-param-reassign
};
const onExit = (elem) => {
  elem.style.height = `${elem.offsetHeight}px`; // eslint-disable-line no-param-reassign
};
const onEntering = (elem) => setTimeout(() => {
  elem.style.height = `${elem.scrollHeight}px`; // eslint-disable-line no-param-reassign
}, 0);
const onExiting = (elem) => setTimeout(() => {
  elem.style.height = '0px'; // eslint-disable-line no-param-reassign
}, 0);
const onFinish = (elem) => {
  elem.style.height = ''; // eslint-disable-line no-param-reassign
};

const Accordion = ({
  className,
  children,
  title,
  ...props
}) => {
  const [open, setOpen] = useState(props.defaultOpen);

  const handleToggle = useCallback(() => setOpen((state) => !state), []);

  return (
    <div className={cn(style.component, className)}>
      <button
        aria-expanded={String(open)}
        className={style.head}
        type="button"
        onClick={handleToggle}
      >
        {title}
      </button>

      <Transition
        in={open}
        timeout={400}
        mountOnEnter
        unmountOnExit
        onEnter={onEnter}
        onExit={onExit}
        onEntering={onEntering}
        onExiting={onExiting}
        onEntered={onFinish}
        onExited={onFinish}
      >
        <div className={style.body}>
          <div className={style.content}>
            {children}
          </div>
        </div>
      </Transition>
    </div>
  );
};

Accordion.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  defaultOpen: PropTypes.bool
};

Accordion.defaultProps = {
  className: null,
  children: null,
  title: null,
  defaultOpen: false
};

export default memo(Accordion);
