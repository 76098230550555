import React, { memo } from 'react';
import PropTypes from 'prop-types';
import CountryFlag from 'react-country-flag';

import style from './Country.module.scss';

const inlineStyle = {
  width: 20,
  height: 15,
  verticalAlign: 'middle'
};

const Country = ({
  code,
  name
}) => (
  <span className={style.component}>
    <CountryFlag
      draggable={false}
      style={inlineStyle}
      countryCode={code}
      svg
    />
    {' '}
    {name}
  </span>
);

Country.propTypes = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};

export default memo(Country);
