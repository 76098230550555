import React, { memo, useCallback } from 'react';

import { ActionsPropTypes, StepsPropType } from '../../types';
import StepHolder from '../StepHolder';

import style from './Step8.module.scss';

const Step8 = ({
  state,
  actions: { finish }
}) => {
  const handleComplete = useCallback(() => {
    finish();
  }, [finish]);

  return (
    <StepHolder className={style.component}>
      <div className={style.circle} />

      <h1 className={style.title}>
        Successfully sent
      </h1>

      <p className={style.description}>
        Thank you for your application! <br /> We will respond to you as soon as possible.
      </p>

      <a
        className={style.link}
        href={state.url}
        onClick={handleComplete}
      >
        Go to 3s.money
      </a>
    </StepHolder>
  );
};

Step8.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step8);
