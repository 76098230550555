import React, { memo, useMemo } from 'react';

import { ActionsPropTypes, StepsPropType } from '../../types';
import Country from '../Country';
import StepComplete from '../StepComplete';

import countries from '../../content/countries.json';

const Step3Complete = ({
  state: { step3 },
  actions: { backward }
}) => {
  const selected = useMemo(() => step3.map((id) => countries.find((c) => c.id === id)), [step3]);

  return (
    <StepComplete
      title="Step 3"
      onClick={() => backward(3)}
    >
      I would like to pay to
      {' '}
      {selected.map((country, index) => (
        <span key={country.id}>
          <Country
            code={country.code}
            name={country.name}
          />
          {index === selected.length - 1 ? '' : ', '}
        </span>
      ))}
    </StepComplete>
  );
};

Step3Complete.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step3Complete);
