import React, {
  memo,
  useCallback,
  useMemo
} from 'react';
import cn from 'classnames';

import { ActionsPropTypes, StepsPropType } from '../../types';
import Button from '../Button';
import PricingCard from '../PricingCard';
import StepHolder from '../StepHolder';
import Status from '../Status';
import Summary from '../Summary';

import style from './Step6Form.module.scss';

import plan from '../../content/tariffPlan.json';
import countries from '../../content/countries.json';

const Step6Form = ({
  state: {
    step1,
    step2,
    step3,
    step5
  },
  actions: { complete }
}) => {
  const country = useMemo(() => countries.find(({ id }) => id === step1), [step1]);

  const minimumAnnualTurnover = useMemo(() => {
    const { volumeIn, volumeOut } = step5;
    return (volumeIn + volumeOut) * 12;
  }, [step5]);

  const uniqueCountries = useMemo(() => {
    const ids = [...new Set([step1, ...step2, ...step3])];
    return countries.filter(({ id }) => ids.includes(id));
  }, [step1, step2, step3]);

  const handleComplete = useCallback(() => {
    complete(6, country.id);
  }, [country, complete]);

  return (
    <StepHolder
      className={style.component}
      padding={false}
    >
      <div className={style.section}>
        <Status
          className={style.status}
          level="success"
        >
          Great news – you’ve been pre-approved!
        </Status>

        <p className={style.recommendation}>
          Here’s the account type we recommend:
        </p>

        <Summary
          country={country}
          countries={uniqueCountries}
        />

        <div className={style.detail}>
          <div className={style.type}>
            <div className={style.from}>
              from
            </div>
            <div className={style.sign}>
              €
            </div>
            <div className={style.value}>
              250
            </div>
            <div className={style.label}>
              per month
            </div>
          </div>

          {(minimumAnnualTurnover < 500000) && (
            <div className={style.description}>
              {/* eslint-disable-next-line max-len */}
              We typically support mid-sized businesses with a minimum annual turnover of €500,000. As a growing business, please apply if you feel 3S Money may still be suitable for your needs.
            </div>
          )}

          <Button
            className={style.submit}
            onClick={handleComplete}
          >
            Open Account
          </Button>
        </div>
      </div>

      <div className={cn(style.section, style.colored)}>
        <PricingCard
          description={plan.description}
          options={plan.options}
        />
      </div>
    </StepHolder>
  );
};

Step6Form.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step6Form);
