import React, { Fragment, memo, useMemo } from 'react';

import { ActionsPropTypes, StepsPropType } from '../../types';
import { isNotEmptyArray } from '../../utils';
import StepComplete from '../StepComplete';

import businessTypes from '../../content/businessTypes.json';

const collectSelected = ({ checked, custom }) => {
  const result = [];

  businessTypes.forEach((bt) => {
    if (isNotEmptyArray(checked[bt.name])) {
      const options = bt.options.filter((o) => checked[bt.name].includes(o.id));
      result.push(...options.map((o) => o.name));
    }

    if (custom[bt.name]) {
      result.push(custom[bt.name]);
    }
  });

  return result;
};

const Step4Complete = ({
  state: { step4 },
  actions: { backward }
}) => {
  const summary = useMemo(() => {
    const products = collectSelected(step4);

    if (!isNotEmptyArray(products)) {
      return 'not selected';
    }

    return products.map((product, index) => (
      <Fragment key={product}>
        <span>
          {product}
        </span>
        {index < (products.length - 1) ? ', ' : ''}
      </Fragment>
    ));
  }, [step4]);

  return (
    <StepComplete
      title="Step 4"
      onClick={() => backward(4)}
    >
      Nature of payments: {summary}
    </StepComplete>
  );
};

Step4Complete.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step4Complete);
