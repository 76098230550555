import React, { memo } from 'react';
import PropTypes from 'prop-types';

import style from './PricingCard.module.scss';

const PricingCard = ({
  description,
  options
}) => (
  <div className={style.component}>
    <div className={style.content}>
      <p className={style.description}>
        {description}
      </p>

      <div className={style.options}>
        {options.map((option) => (
          <div
            className={style.option}
            key={option.name}
          >
            <div className={style.label}>
              {option.name}
            </div>

            <div className={style.summary}>
              <div className={style.sign}>
                {option.sign}
              </div>

              <div className={style.value}>
                {option.value}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

PricingCard.propTypes = {
  description: PropTypes.string,
  options: PropTypes.array
};

PricingCard.defaultProps = {
  description: null,
  options: []
};

export default memo(PricingCard);
