import React, { memo, useMemo } from 'react';

import { ActionsPropTypes, StepsPropType } from '../../types';
import StepComplete from '../StepComplete';

import countries from '../../content/countries.json';

const globalAccount = 'Global Business Account';

const Step6Complete = ({
  state: { step1 },
  actions: { backward }
}) => {
  const account = useMemo(() => {
    const country = countries.find(({ id }) => id === step1);
    return country ? country.account || globalAccount : globalAccount;
  }, [step1]);

  return (
    <StepComplete
      title="Step 6"
      onClick={() => backward(6)}
    >
      {account ? `${account} selected` : 'account not selected'}
    </StepComplete>
  );
};

Step6Complete.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step6Complete);
