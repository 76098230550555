import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../utils';

import style from './ButtonRemove.module.scss';

const ButtonRemove = ({
  className,
  onClick
}) => {
  const handleClick = useCallback((event) => {
    event.preventDefault();
    onClick();
  }, [onClick]);

  return (
    <button
      aria-label="Remove"
      className={cn(style.component, className)}
      type="button"
      title="Remove"
      onClick={handleClick}
    />
  );
};

ButtonRemove.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func
};

ButtonRemove.defaultProps = {
  className: null,
  onClick: noop
};

export default memo(ButtonRemove);
