/* eslint-disable max-len */
import React, { Fragment, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import CountryFlag from 'react-country-flag';

import {
  availableCodes,
  availableCurrencies
} from '../../utils';

import style from './Summary.module.scss';

const inlineStyle = {
  width: 40,
  height: 30
};

const Summary = ({
  country,
  countries
}) => {
  const countryNames = useMemo(() => [...new Set(countries.map((c) => c.name))], [countries]);

  const currencies = useMemo(() => {
    const currencySet = new Set();
    countries.forEach((c) => {
      availableCurrencies(c).forEach(currencySet.add, currencySet);
    });
    return [...currencySet];
  }, [countries]);

  const currencyCodes = useMemo(() => availableCodes(country), [country]);

  return (
    <div className={style.component}>
      <div className={style.head}>
        <div className={style.content}>
          <div className={style.title}>
            <CountryFlag
              draggable={false}
              style={inlineStyle}
              countryCode={country.code}
              svg
            />

            <div className={style.name}>
              {country.account || 'Global Business Account'}
            </div>
          </div>
        </div>

        <div className={style.currencies}>
          <ul className={style.list}>
            {currencyCodes.map((item) => (
              <li
                className={style.item}
                key={item}
              >
                <div className={style.currency}>
                  {item}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className={style.body}>
        <ul className={style.options}>
          <li className={style.option}>
            {country.iban || 'GB IBAN issued in your business name'}
          </li>

          <li className={style.option}>
            Collect from and pay to
            {' '}
            {countryNames.map((c, i) => (
              <Fragment key={c}>
                <strong>{c}</strong>
                {i < (countryNames.length - 1) ? ', ' : ''}
              </Fragment>
            ))}
          </li>

          <li className={style.option}>
            Access
            {' '}
            {currencies.map((c, i) => (
              <Fragment key={c}>
                <strong>{c}</strong>
                {i < (currencies.length - 1) ? ', ' : ''}
              </Fragment>
            ))}
          </li>

          <li className={style.option}>
            No chatbots — just a dedicated client manager with 24/7 human support
          </li>

          <li className={style.option}>
            Joint signatories and multi-level team access
          </li>
        </ul>
      </div>
    </div>
  );
};

Summary.propTypes = {
  country: PropTypes.object,
  countries: PropTypes.array
};

Summary.defaultProps = {
  country: null,
  countries: []
};

export default memo(Summary);
