import React, {
  forwardRef,
  memo,
  useCallback
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import NumberFormat from 'react-number-format';

import { noop } from '../../utils';

import style from './NumberInput.module.scss';

const NumberInput = forwardRef(({
  className,
  id,
  label,
  name,
  step,
  type,
  value,
  onChange
}, ref) => {
  const handleChange = useCallback(({ floatValue }) => {
    onChange(floatValue, name);
  }, [name, onChange]);

  const handleDecrease = useCallback(() => {
    onChange(Math.max(0, value - step), name);
  }, [name, step, value, onChange]);

  const handleIncrease = useCallback(() => {
    onChange(Math.max(0, value + step), name);
  }, [name, step, value, onChange]);

  return (
    <div className={cn(style.component, className)}>
      {!!label && (
        <label
          htmlFor={id || name}
          className={style.label}
        >
          {label}
        </label>
      )}

      <div className={style.field}>
        <button
          aria-label="Decrease"
          className={cn(style.control, style.decrease)}
          type="button"
          onClick={handleDecrease}
        />

        <NumberFormat
          allowNegative={false}
          className={style.input}
          getInputRef={ref}
          defaultValue={value}
          id={id || name}
          name={name}
          prefix="€ "
          thousandSeparator=" "
          value={value}
          type={type}
          onValueChange={handleChange}
        />

        <button
          aria-label="Increase"
          className={cn(style.control, style.increase)}
          type="button"
          onClick={handleIncrease}
        />
      </div>
    </div>
  );
});

NumberInput.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  step: PropTypes.number,
  type: PropTypes.oneOf(['text', 'tel', 'password']),
  value: PropTypes.number,
  onChange: PropTypes.func
};

NumberInput.defaultProps = {
  className: null,
  id: null,
  label: null,
  name: null,
  step: 1,
  type: 'text',
  value: null,
  onChange: noop
};

export default memo(NumberInput);
