import React, {
  memo,
  useCallback,
  useMemo,
  useState
} from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import Fuse from 'fuse.js';

import { noop } from '../../utils';

import style from './SelectInput.module.scss';

const getSuggestionValue = (suggest) => suggest.name;

const SelectInput = ({
  name,
  options,
  placeholder,
  value,
  onChange
}) => {
  const [search, setSearch] = useState(value || '');
  const [suggestions, setSuggestions] = useState(options);

  const fuse = useMemo(() => (
    new Fuse(options, {
      keys: ['name'],
      threshold: 0.3
    })
  ), [options]);

  const handleSearch = useCallback((event, { newValue }) => {
    setSearch(newValue);
  }, []);

  const onSuggestionSelected = useCallback((event, { suggestion }) => {
    setSearch('');
    setSuggestions(options);
    onChange(suggestion.value, name);
  }, [name, options, onChange]);

  const onSuggestionsFetchRequested = useCallback(({ value: searchValue }) => {
    setSuggestions(fuse.search(searchValue).map(({ item }) => item));
  }, [fuse]);

  const onSuggestionsClearRequested = useCallback(() => {
    setSuggestions(options);
  }, [options]);

  const inputProps = useMemo(() => ({
    placeholder,
    name,
    value: search,
    onChange: handleSearch
  }), [
    name,
    placeholder,
    search,
    handleSearch
  ]);

  return (
    <Autosuggest
      inputProps={inputProps}
      highlightFirstSuggestion
      suggestions={suggestions}
      theme={style}
      shouldRenderSuggestions={() => true}
      getSuggestionValue={getSuggestionValue}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionSelected={onSuggestionSelected}
      renderSuggestion={getSuggestionValue}
    />
  );
};

const OptionPropType = PropTypes.shape({
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
});

SelectInput.propTypes = {
  name: PropTypes.string,
  options: PropTypes.arrayOf(OptionPropType),
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

SelectInput.defaultProps = {
  name: null,
  options: [],
  placeholder: null,
  value: null,
  onChange: noop
};

export default memo(SelectInput);
