import React, { memo, useMemo } from 'react';

import { ActionsPropTypes, StepsPropType } from '../../types';
import { availableCurrencies } from '../../utils';
import Country from '../Country';
import StepComplete from '../StepComplete';

import countries from '../../content/countries.json';

import style from './Step2Complete.module.scss';

const Step2Complete = ({
  state: { step2 },
  actions: { backward }
}) => {
  const selected = useMemo(() => step2.map((id) => countries.find((c) => c.id === id)), [step2]);

  return (
    <StepComplete
      title="Step 2"
      onClick={() => backward(2)}
    >
      I would like to collect money from
      {' '}
      <ul className={style.list}>
        {selected.map((country) => (
          <li
            className={style.item}
            key={country.id}
          >
            <Country
              code={country.code}
              name={country.name}
            />
            {': '}
            {availableCurrencies(country).join(', ')}
          </li>
        ))}
      </ul>
    </StepComplete>
  );
};

Step2Complete.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step2Complete);
