import React, {
  useCallback,
  memo
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../utils';

import style from './BoolInput.module.scss';

const BoolInput = ({
  checked,
  className,
  id,
  label,
  labelClassName,
  name,
  type,
  value,
  onChange
}) => {
  const handleChange = useCallback(({ target }) => {
    onChange(value, name, target.checked);
  }, [name, value, onChange]);

  const composedClassName = cn(style.component, className, {
    [style.checkbox]: type === 'checkbox',
    [style.radio]: type === 'radio'
  });

  return (
    <label
      className={composedClassName}
      htmlFor={id}
    >
      <input
        checked={checked}
        className={style.input}
        id={id}
        name={name}
        type={type}
        value={value}
        onChange={handleChange}
      />

      <div className={cn(style.label, labelClassName)}>
        <span className={style.check} />

        {label}
      </div>
    </label>
  );
};

BoolInput.propTypes = {
  checked: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.any,
  labelClassName: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf([
    'checkbox',
    'radio'
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onChange: PropTypes.func
};

BoolInput.defaultProps = {
  checked: false,
  className: null,
  id: null,
  label: null,
  labelClassName: null,
  name: null,
  type: 'checkbox',
  value: null,
  onChange: noop
};

export default memo(BoolInput);
