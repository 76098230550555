import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './StepHeader.module.scss';

const StepHeader = ({
  className,
  title,
  description
}) => (
  <div className={cn(style.component, className)}>
    <h1 className={style.title}>
      {title}
    </h1>

    <p className={style.description}>
      {description}
    </p>
  </div>
);

StepHeader.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string
};

StepHeader.defaultProps = {
  className: null,
  title: null,
  description: null
};

export default memo(StepHeader);
