import React, { forwardRef, memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Button.module.scss';

const Button = forwardRef(({
  className,
  children,
  type,
  ...props
}, ref) => (
  <button
    className={cn(style.component, className)}
    ref={ref}
    // eslint-disable-next-line react/button-has-type
    type={type}
    {...props}
  >
    {children}
  </button>
));

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  type: PropTypes.oneOf([
    'button',
    'submit',
    'reset'
  ])
};

Button.defaultProps = {
  className: null,
  children: null,
  type: 'button'
};

export default memo(Button);
