import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import validate from 'validate.js';

import { ActionsPropTypes, StepsPropType } from '../../types';
import { availableCurrencies, isNotEmptyArray } from '../../utils';
import Button from '../Button';
import BoolInput from '../BoolInput';
import ButtonRemove from '../ButtonRemove';
import Country from '../Country';
import ErrorMessage from '../ErrorMessage';
import SelectInput from '../SelectInput';
import StepHeader from '../StepHeader';
import StepHolder from '../StepHolder';
import Status from '../Status';

import style from './Step2Form.module.scss';

import countries from '../../content/countries.json';

const Step2Form = ({
  state: {
    step1,
    step2
  },
  actions: { complete }
}) => {
  const [state, setState] = useState(() => {
    let variants = step2.map((id) => countries.find((c) => c.id === id));

    if (!isNotEmptyArray(variants)) {
      variants = countries.filter((c) => c.step2 || (c.id === step1));
    }

    variants = variants.map((c) => ({
      deletable: step2.includes(c.id),
      item: c
    }));

    return {
      selected: step2,
      variants
    };
  });

  const [error, setError] = useState(null);

  const handleCheck = useCallback((value) => {
    setState((prev) => {
      let selected = [...prev.selected];
      const index = selected.indexOf(value);

      if (index < 0) {
        selected = [...selected, value];
      } else {
        selected.splice(index, 1);
      }

      return {
        ...prev,
        selected
      };
    });
  }, []);

  const handleSelect = useCallback((value) => {
    setState((prev) => {
      const variant = prev.variants.find(({ item }) => item.id === value);

      if (variant) {
        const index = prev.selected.indexOf(value);

        if (index >= 0) {
          return prev;
        }

        return {
          ...prev,
          selected: [...prev.selected, value]
        };
      }

      const country = countries.find((c) => c.id === value);

      if (!country) {
        return prev;
      }

      return {
        ...prev,
        selected: [...prev.selected, value],
        variants: [...prev.variants, { deletable: true, item: country }]
      };
    });
  }, []);

  const handleRemove = useCallback((value) => {
    setState((prev) => {
      const selected = prev.selected.filter((id) => id !== value);
      let variants = prev.variants.filter(({ item }) => item.id !== value);

      if (!isNotEmptyArray(variants)) {
        variants = countries.filter((c) => c.step2 || (c.id === step1));
        variants = variants.map((v) => ({ deletable: false, item: v }));
      }

      return {
        ...prev,
        selected,
        variants
      };
    });
  }, [step1]);

  const handleComplete = useCallback(() => {
    if (validate.isEmpty(state.selected)) {
      setError('Country is not selected');
      return;
    }

    const blocked = countries.filter((c) => state.selected.includes(c.id) && c.blocked);
    if (isNotEmptyArray(blocked)) {
      const names = blocked.map((c) => c.name).join(', ');
      const article = blocked.length > 1 ? ' are ' : ' ';
      const text = 'not supported';
      setError(`${names}${article}${text}`);
      return;
    }

    complete(2, state.selected);
  }, [complete, state.selected]);

  const options = useMemo(() => countries.map(({ name, id }) => ({ name, value: id })), []);

  useEffect(() => {
    if (error) {
      setError(null);
    }
  }, [state.selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StepHolder className={style.component}>
      <StepHeader
        title="Step 2"
        description="I would like to collect money from"
      />

      {state.variants.map(({ item, deletable }) => (
        <div
          key={item.id}
          className={style.field}
        >
          <BoolInput
            checked={state.selected.includes(item.id)}
            id={`step2-${item.id}`}
            label={(
              <span className={style.label}>
                <div className={style.inner}>
                  <Country
                    code={item.code}
                    name={item.name}
                  />

                  <span className={style.currencies}>
                    {availableCurrencies(item).join(', ')}
                  </span>
                </div>

                {deletable && (
                  <ButtonRemove onClick={() => handleRemove(item.id)} />
                )}
              </span>
            )}
            name="country"
            type="checkbox"
            value={item.id}
            onChange={handleCheck}
          />

          {state.selected.includes(item.id) && (
            <>
              {!!item.blocked && (
                <Status
                  className={style.status}
                  level="error"
                >
                  This region is not supported
                </Status>
              )}

              {!!item.collect && (
                <Status
                  className={style.status}
                  level="success"
                  mark
                >
                  {item.collect}
                </Status>
              )}
            </>
          )}
        </div>
      ))}

      <div className={style.field}>
        <SelectInput
          search
          placeholder="Other country"
          options={options}
          onChange={handleSelect}
        />
      </div>

      {!!error && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}

      <Button
        className={style.submit}
        type="button"
        onClick={handleComplete}
      >
        Continue
      </Button>
    </StepHolder>
  );
};

Step2Form.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step2Form);
