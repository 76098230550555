import React, { memo } from 'react';
import NumberFormat from 'react-number-format';

import { ActionsPropTypes, StepsPropType } from '../../types';
import StepComplete from '../StepComplete';

const Step5Complete = ({
  state: { step5 },
  actions: { backward }
}) => (
  <StepComplete
    title="Step 5"
    onClick={() => backward(5)}
  >
    Monthly volumes
    {' ↓in: '}
    <NumberFormat
      value={step5.volumeIn}
      displayType="text"
      thousandSeparator=" "
      prefix="€ "
    />
    {'; ↑out: '}
    <NumberFormat
      value={step5.volumeOut}
      displayType="text"
      thousandSeparator=" "
      prefix="€ "
    />
  </StepComplete>
);

Step5Complete.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step5Complete);
