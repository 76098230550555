import React, { memo, useMemo } from 'react';

import { ActionsPropTypes, StepsPropType } from '../../types';
import Country from '../Country';
import StepComplete from '../StepComplete';

import countries from '../../content/countries.json';

const Step1Complete = ({
  state: { step1 },
  actions: { backward }
}) => {
  const country = useMemo(() => countries.find(({ id }) => id === step1), [step1]);

  return (
    <StepComplete
      title="Step 1"
      onClick={() => backward(1)}
    >
      My company is registered in
      {' '}
      <Country
        code={country.code}
        name={country.name}
      />
    </StepComplete>
  );
};

Step1Complete.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step1Complete);
