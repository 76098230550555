import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import validate from 'validate.js';

import { ActionsPropTypes, StepsPropType } from '../../types';
import BoolInput from '../BoolInput';
import Button from '../Button';
import ButtonRemove from '../ButtonRemove';
import Country from '../Country';
import ErrorMessage from '../ErrorMessage';
import SelectInput from '../SelectInput';
import StepHeader from '../StepHeader';
import StepHolder from '../StepHolder';
import Status from '../Status';

import style from './Step1Form.module.scss';

import countries from '../../content/countries.json';

const Step1Form = ({
  state: { step1 },
  actions: { complete }
}) => {
  const [selected, setSelected] = useState(step1);
  const [variants, setVariants] = useState([]);
  const [error, setError] = useState(null);

  const handleCheck = useCallback((value) => {
    setSelected(value);
  }, []);

  const handleSelect = useCallback((value) => {
    setSelected(value);
  }, []);

  const handleDelete = useCallback(() => {
    setSelected(null);
  }, []);

  const handleComplete = useCallback(() => {
    const active = countries.find((c) => c.id === selected);

    if (!active) {
      setError('Country is not selected');
      return;
    }

    if (!active.reg) {
      setError('Region is not available');
      return;
    }

    complete(1, selected);
  }, [complete, selected]);

  const options = useMemo(() => countries.map(({ id, name }) => ({ name, value: id })), []);

  const country = useMemo(() => countries.find(({ id }) => id === selected), [selected]);

  useEffect(() => {
    setVariants(() => {
      const active = countries.find(({ id }) => id === selected);
      let items = [active];

      if (!active) {
        items = countries.filter((c) => c.step1);
        items.sort((p, n) => n.step1 - p.step1);
      }

      return items.map((c) => ({ deletable: !c.step1, item: c }));
    });
  }, [selected]);

  useEffect(() => {
    if (error && !validate.isEmpty(selected)) {
      setError(null);
    }
  }, [error, selected]);

  return (
    <StepHolder className={style.component}>
      <StepHeader
        title="Step 1"
        description="My company is registered in"
      />

      {variants.map(({ deletable, item }) => (
        <div
          className={style.field}
          key={item.id}
        >
          <BoolInput
            checked={item.id === selected}
            id={`step1-${item.id}`}
            label={(
              <span className={style.label}>
                <Country
                  code={item.code}
                  name={item.name}
                />

                {(deletable || (item.id === selected)) && (
                  <ButtonRemove onClick={handleDelete} />
                )}
              </span>
            )}
            name="country"
            type="radio"
            value={item.id}
            onChange={handleCheck}
          />
        </div>
      ))}

      {!!country && !country.reg && (
        <Status
          className={style.message}
          level="error"
        >
          This region is not supported
        </Status>
      )}

      {(!!country && !!country.reg && !!country.iban) && (
        <Status
          className={style.message}
          level="success"
          mark
        >
          {country.iban}
        </Status>
      )}

      {!country && (
        <div className={style.field}>
          <SelectInput
            options={options}
            placeholder="Other country"
            search
            onChange={handleSelect}
          />
        </div>
      )}

      {!!error && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}

      <Button
        className={style.submit}
        type="button"
        onClick={handleComplete}
      >
        Continue
      </Button>
    </StepHolder>
  );
};

Step1Form.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step1Form);
