import PropTypes from 'prop-types';

const ActionsPropTypes = PropTypes.shape({
  complete: PropTypes.func.isRequired,

  backward: PropTypes.func.isRequired,

  finish: PropTypes.func.isRequired
});

export default ActionsPropTypes;
