import React, {
  useCallback,
  forwardRef,
  memo, useMemo
} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import TextareaAutosize from 'react-textarea-autosize';

import { noop } from '../../utils';
import ErrorMessage from '../ErrorMessage';

import style from './TextInput.module.scss';

const TextInput = forwardRef(({
  className,
  error,
  id,
  label,
  name,
  type,
  value,
  onChange,
  ...props
}, ref) => {
  const isTextarea = useMemo(() => (type === 'textarea'), [type]);

  const handleChange = useCallback(({ target }) => {
    onChange(target.value, name);
  }, [name, onChange]);

  const inputClassName = cn(style.input, {
    [style.empty]: !value,
    [style.error]: !!error
  });

  const message = useMemo(() => {
    if (Array.isArray(error)) {
      return error[0];
    }

    return error;
  }, [error]);

  return (
    <div className={cn(style.component, className)}>
      {isTextarea && (
        <TextareaAutosize
          aria-describedby={`error-${id}`}
          className={cn(inputClassName, style.textarea)}
          id={id}
          name={name}
          maxRows={3}
          ref={ref || noop}
          value={value}
          onChange={handleChange}
          {...props} // eslint-disable-line react/jsx-props-no-spreading
        />
      )}

      {!isTextarea && (
        <input
          aria-describedby={`error-${id}`}
          className={inputClassName}
          id={id}
          name={name}
          ref={ref}
          type={type}
          value={value}
          onChange={handleChange}
          {...props}
        />
      )}

      {!!label && (
        <label
          className={style.label}
          htmlFor={id}
        >
          {label}
        </label>
      )}

      {!!message && (
        <ErrorMessage
          id={`error-${id}`}
          className={style.message}
        >
          {message}
        </ErrorMessage>
      )}
    </div>
  );
});

TextInput.propTypes = {
  className: PropTypes.string,
  error: PropTypes.array,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'tel',
    'password',
    'textarea',
    'number'
  ]),
  value: PropTypes.string,
  onChange: PropTypes.func
};

TextInput.defaultProps = {
  className: null,
  error: null,
  id: null,
  label: null,
  name: null,
  type: 'text',
  value: '',
  onChange: noop
};

export default memo(TextInput);
