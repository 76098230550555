export const noop = () => {};

const EUR = 'EUR';
const USD = 'USD';
const GBP = 'GBP';

export const availableCurrencies = (country) => {
  const items = [];

  if (country.local) {
    const local = country.local.replace(/\s/g, '');
    items.push(...local.split(','));
  }

  if (country.eur && !items.includes(EUR)) {
    items.push(EUR);
  }

  if (country.usd && !items.includes(USD)) {
    items.push(USD);
  }

  if (country.gbp && !items.includes(GBP)) {
    items.push(GBP);
  }

  return items;
};

export const availableCodes = (country) => {
  const items = [];

  if (country.eur) {
    items.push('€');
  }

  if (country.usd) {
    items.push('$');
  }

  if (country.gbp) {
    items.push('£');
  }

  return items;
};

export const isNotEmptyArray = (array) => Array.isArray(array) && !!array.length;
