import React, {
  memo,
  useCallback,
  useEffect,
  useState
} from 'react';
import validate from 'validate.js';

import { ActionsPropTypes, StepsPropType } from '../../types';
import { isNotEmptyArray } from '../../utils';
import Accordion from '../Accordion';
import Button from '../Button';
import BoolInput from '../BoolInput';
import StepHeader from '../StepHeader';
import StepHolder from '../StepHolder';
import TextInput from '../TextInput';
import Status from '../Status';
import ErrorMessage from '../ErrorMessage';

import style from './Step4Form.module.scss';

import businessTypes from '../../content/businessTypes.json';

const processCustom = (custom) => {
  const result = {};

  Object.keys(custom).forEach((key) => {
    if (!validate.isEmpty(custom[key])) {
      result[key] = custom[key].trim();
    }
  });

  return result;
};

const Step4Form = ({
  state: { step4 },
  actions: { complete }
}) => {
  const [checked, setChecked] = useState(step4.checked);
  const [error, setError] = useState(null);
  const [custom, setCustom] = useState(step4.custom);

  const handleCheck = useCallback((payment, id) => {
    setChecked((prev) => {
      const next = [...(prev[payment] || [])];
      const index = next.indexOf(id);

      if (index < 0) {
        next.push(id);
      } else {
        next.splice(index, 1);
      }

      return {
        ...prev,
        [payment]: next
      };
    });
  }, []);

  const handleChange = useCallback((value, name) => {
    setCustom((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleSubmit = useCallback(() => {
    const blocked = businessTypes.find((v) => v.blocked && isNotEmptyArray(checked[v.name]));

    if (blocked) {
      setError(blocked.message);
      return;
    }

    complete(4, {
      checked,
      custom: processCustom(custom)
    });
  }, [complete, custom, checked]);

  useEffect(() => {
    const blocked = businessTypes.find((v) => v.blocked && isNotEmptyArray(checked[v.name]));

    if (error && !blocked) {
      setError(null);
    }
  }, [error, checked]);

  return (
    <StepHolder className={style.component}>
      <StepHeader
        title="Step 4"
        description="Nature of payments"
      />

      {businessTypes.map((bt) => (
        <div
          className={style.payment}
          key={bt.name}
        >
          <Accordion
            defaultOpen={isNotEmptyArray(checked[bt.name]) || !validate.isEmpty(custom[bt.name])}
            title={bt.title}
          >
            <div className={style.section}>
              <div className={style.row}>
                {bt.options.map((option) => (
                  <fieldset
                    className={style.col}
                    key={option.id}
                  >
                    <BoolInput
                      checked={(checked[bt.name] || []).includes(option.id)}
                      id={`step4-${bt.name}-${option.id}`}
                      label={(
                        <div className={style.inputContent}>
                          {option.name}
                        </div>
                      )}
                      labelClassName={style.inputLabel}
                      name={`step4-${bt.name}-${option.id}`}
                      type="checkbox"
                      value={option.id}
                      onChange={() => handleCheck(bt.name, option.id)}
                    />
                  </fieldset>
                ))}
              </div>
            </div>

            <div className={style.section}>
              {(bt.blocked && isNotEmptyArray(checked[bt.name])) && (
                <Status level="error">
                  {bt.message}
                </Status>
              )}
            </div>

            <div className={style.section}>
              <div className={style.row}>
                <div className={style.col}>
                  <TextInput
                    id={`step4-${bt.name}`}
                    label="Other nature of payment"
                    name={bt.name}
                    value={custom[bt.name]}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </Accordion>
        </div>
      ))}

      {!!error && (
        <ErrorMessage>
          {error}
        </ErrorMessage>
      )}

      <Button
        className={style.submit}
        type="button"
        onClick={handleSubmit}
      >
        Continue
      </Button>
    </StepHolder>
  );
};

Step4Form.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step4Form);
