import PropTypes from 'prop-types';

const StepsPropType = PropTypes.shape({
  step1: PropTypes.number,
  step2: PropTypes.arrayOf(PropTypes.number),
  step3: PropTypes.arrayOf(PropTypes.number),
  step4: PropTypes.object,
  step5: PropTypes.shape({
    volumeIn: PropTypes.number,
    volumeOut: PropTypes.number
  }),
  step6: PropTypes.any,
  step7: PropTypes.shape({
    fullName: PropTypes.string,
    company: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    message: PropTypes.string
  })
});

export default StepsPropType;
