import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Layout.module.scss';

const Layout = ({
  className,
  children
}) => (
  <main className={cn(style.component, className)}>
    {children}
  </main>
);

Layout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

Layout.defaultProps = {
  className: null,
  children: null
};

export default memo(Layout);
