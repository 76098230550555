import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './StepHolder.module.scss';

const StepHolder = ({
  className,
  children,
  padding
}) => (
  <section
    className={cn(style.component, {
      [style.padding]: padding
    }, className)}
  >
    {children}
  </section>
);

StepHolder.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  padding: PropTypes.bool
};

StepHolder.defaultProps = {
  className: null,
  children: null,
  padding: true
};

export default memo(StepHolder);
