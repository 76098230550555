import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { noop } from '../../utils';

import style from './StepComplete.module.scss';

const StepComplete = ({
  className,
  children,
  title,
  onClick
}) => (
  <button
    aria-label={`Back to ${title}`}
    className={cn(style.component, className)}
    title={`Back to ${title}`}
    type="button"
    onClick={onClick}
  >
    <div className={style.title}>
      {title}
    </div>

    <div className={style.detail}>
      {children}
    </div>
  </button>
);

StepComplete.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  title: PropTypes.string,
  onClick: PropTypes.func
};

StepComplete.defaultProps = {
  className: null,
  children: null,
  title: null,
  onClick: noop
};

export default memo(StepComplete);
