import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './ErrorMessage.module.scss';

const ErrorMessage = ({
  className,
  children,
  ...props
}) => (
  <div
    className={cn(style.component, className)}
    {...props}
  >
    {children}
  </div>
);

ErrorMessage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any
};

ErrorMessage.defaultProps = {
  className: null,
  children: null
};

export default memo(ErrorMessage);
