import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import style from './Status.module.scss';

const Status = ({
  className,
  children,
  level,
  mark
}) => (
  <div className={cn(style.component, style[level], className)}>
    {mark && (
      <span className={style.mark} />
    )}

    {children}
  </div>
);

Status.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  level: PropTypes.oneOf(['success', 'error']),
  mark: PropTypes.bool
};

Status.defaultProps = {
  className: null,
  children: null,
  level: null,
  mark: false
};

export default memo(Status);
