import React, { memo, useCallback, useState } from 'react';
import validate from 'validate.js';

import { ActionsPropTypes, StepsPropType } from '../../types';
import Button from '../Button';
import ErrorMessage from '../ErrorMessage';
import NumberInput from '../NumberInput';
import StepHeader from '../StepHeader';
import StepHolder from '../StepHolder';

import style from './Step5Form.module.scss';

const constraints = {
  volumeIn: {
    presence: {
      allowEmpty: false,
      message: '^"Volume In" can\'t be blank'
    }
  },
  volumeOut: {
    presence: {
      allowEmpty: false,
      message: '^"Volume Out" can\'t be blank'
    }
  }
};

const STEP = 10000;

const Step5Form = ({
  state: { step5 },
  actions: { complete }
}) => {
  const [error, setErrors] = useState(null);

  const [form, setForm] = useState({
    volumeIn: step5.volumeIn || 0,
    volumeOut: step5.volumeOut || 0
  });

  const handleChange = useCallback((value, name) => {
    setForm((prev) => ({ ...prev, [name]: value }));
    setErrors(null);
  }, []);

  const handleComplete = useCallback(() => {
    const validation = validate(form, constraints, { format: 'flat' });
    setErrors(validation);

    if (!validation) {
      complete(5, form);
    }
  }, [complete, form]);

  return (
    <StepHolder className={style.component}>
      <StepHeader
        title="Step 5"
        description="Monthly volumes"
      />

      <div className={style.row}>
        <div className={style.col}>
          <NumberInput
            defaultValue={0}
            label="↓ In"
            name="volumeIn"
            step={STEP}
            value={form.volumeIn}
            onChange={handleChange}
          />
        </div>

        <div className={style.col}>
          <NumberInput
            defaultValue={0}
            label="↑ Out"
            name="volumeOut"
            step={STEP}
            value={form.volumeOut}
            onChange={handleChange}
          />
        </div>
      </div>

      {!validate.isEmpty(error) && (
        <ErrorMessage>
          {error.join(', ')}
        </ErrorMessage>
      )}

      <Button
        className={style.submit}
        type="button"
        onClick={handleComplete}
      >
        Continue
      </Button>
    </StepHolder>
  );
};

Step5Form.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step5Form);
