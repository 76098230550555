import React, { memo, useMemo } from 'react';

import useSteps from '../store/steps';

import Layout from './Layout';

import Step1Complete from '../components/Step1Complete';
import Step1Form from '../components/Step1Form';
import Step2Complete from '../components/Step2Complete';
import Step2Form from '../components/Step2Form';
import Step3Complete from '../components/Step3Complete';
import Step3Form from '../components/Step3Form';
import Step4Complete from '../components/Step4Complete';
import Step4Form from '../components/Step4Form';
import Step5Complete from '../components/Step5Complete';
import Step5Form from '../components/Step5Form';
import Step6Complete from '../components/Step6Complete';
import Step6Form from '../components/Step6Form';
import Step7Form from '../components/Step7Form';
import Step8Form from '../components/Step8Form';

const App = () => {
  const [state, actions] = useSteps();

  const { progress } = state;

  const finished = useMemo(() => progress === 8, [progress]);

  return (
    <Layout>
      {(progress === 1 && !finished) && (
        <Step1Form
          state={state}
          actions={actions}
        />
      )}

      {(progress > 1 && !finished) && (
        <Step1Complete
          state={state}
          actions={actions}
        />
      )}

      {(progress === 2 && !finished) && (
        <Step2Form
          state={state}
          actions={actions}
        />
      )}

      {(progress > 2 && !finished) && (
        <Step2Complete
          state={state}
          actions={actions}
        />
      )}

      {(progress === 3 && !finished) && (
        <Step3Form
          state={state}
          actions={actions}
        />
      )}

      {(progress > 3 && !finished) && (
        <Step3Complete
          state={state}
          actions={actions}
        />
      )}

      {(progress === 4 && !finished) && (
        <Step4Form
          state={state}
          actions={actions}
        />
      )}

      {(progress > 4 && !finished) && (
        <Step4Complete
          state={state}
          actions={actions}
        />
      )}

      {(progress === 5 && !finished) && (
        <Step5Form
          state={state}
          actions={actions}
        />
      )}

      {(progress > 5 && !finished) && (
        <Step5Complete
          state={state}
          actions={actions}
        />
      )}

      {(progress === 6 && !finished) && (
        <Step6Form
          state={state}
          actions={actions}
        />
      )}

      {(progress > 6 && !finished) && (
        <Step6Complete
          state={state}
          actions={actions}
        />
      )}

      {(progress === 7 && !finished) && (
        <Step7Form
          state={state}
          actions={actions}
        />
      )}

      {(progress === 8) && (
        <Step8Form
          state={state}
          actions={actions}
        />
      )}
    </Layout>
  );
};

export default memo(App);
