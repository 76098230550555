import React, {
  memo,
  useCallback,
  useMemo,
  useState
} from 'react';
import validate from 'validate.js';

import { ActionsPropTypes, StepsPropType } from '../../types';
import Button from '../Button';
import StepHolder from '../StepHolder';
import Summary from '../Summary';
import TextInput from '../TextInput';

import style from './Step7Form.module.scss';

import countries from '../../content/countries.json';

const constraints = {
  fullName: {
    presence: {
      allowEmpty: false,
      message: '^Please fill in your full name'
    }
  },
  company: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your company'
    }
  },
  email: {
    email: {
      message: '^Please enter your email address'
    }
  },
  phone: {
    presence: {
      allowEmpty: false,
      message: '^Please enter your phone number'
    }
  }
};

const Step7Form = ({
  state: {
    step1,
    step2,
    step3,
    step7
  },
  actions: {
    complete
  }
}) => {
  const [form, setForm] = useState({
    fullName: step7.fullName || '',
    company: step7.company || '',
    email: step7.email || '',
    phone: step7.phone || '',
    message: step7.message || ''
  });

  const [errors, setErrors] = useState({});

  const handleChange = useCallback((value, name) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  }, []);

  const handleBlur = useCallback((event) => {
    const { name } = event.target;

    setErrors((prev) => ({
      ...prev,
      [name]: validate.single(form[name], constraints[name])
    }));
  }, [form]);

  const handleSubmit = useCallback((event) => {
    event.preventDefault();

    const validation = validate(form, constraints);
    setErrors(validation);

    if (validation) {
      return;
    }

    complete(7, {
      fullName: form.fullName.trim(),
      company: form.company.trim(),
      email: form.email.trim(),
      phone: form.phone.trim(),
      message: form.message.trim()
    });
  }, [complete, form]);

  const uniqueCountries = useMemo(() => {
    const ids = [...new Set([step1, ...step2, ...step3])];
    return countries.filter(({ id }) => ids.includes(id));
  }, [step1, step2, step3]);

  const country = useMemo(() => countries.find((c) => c.id === step1), [step1]);

  return (
    <StepHolder className={style.component}>
      <div className={style.plan}>
        <Summary
          country={country}
          countries={uniqueCountries}
        />
      </div>

      <p className={style.description}>
        {/* eslint-disable-next-line max-len */}
        Please fill in the contact information below to get started on your account opening. You will receive an invitation to our online Onboarding Process via email
      </p>

      <form
        noValidate
        onSubmit={handleSubmit}
      >
        <div className={style.row}>
          <div className={style.col}>
            <TextInput
              error={errors.fullName}
              label="Full name"
              name="fullName"
              required
              value={form.fullName}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>

          <div className={style.col}>
            <TextInput
              error={errors.company}
              label="Company"
              name="company"
              required
              value={form.company}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>

          <div className={style.col}>
            <TextInput
              error={errors.email}
              label="Email"
              name="email"
              required
              type="email"
              value={form.email}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>

          <div className={style.col}>
            <TextInput
              error={errors.phone}
              label="Contact number"
              name="phone"
              required
              type="tel"
              value={form.phone}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </div>
        </div>

        <div>
          <TextInput
            label="Message"
            name="message"
            rows={1}
            type="textarea"
            value={form.message}
            onChange={handleChange}
          />
        </div>

        <Button
          className={style.submit}
          type="submit"
        >
          Send
        </Button>
      </form>
    </StepHolder>
  );
};

Step7Form.propTypes = {
  state: StepsPropType.isRequired,
  actions: ActionsPropTypes.isRequired
};

export default memo(Step7Form);
