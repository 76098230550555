import {
  useCallback,
  useEffect,
  useMemo,
  useReducer
} from 'react';
import GitInfo from 'react-git-info/macro';

import { createReducer } from './utils';
import useLocalStorage from '../hooks/useLocalStorage';

// TODO: get from env
const STORAGE_KEY = '3smoney-appform';

const COMPLETE = 'steps/COMPLETE';
const BACKWARD = 'steps/BACKWARD';
const FINISH = 'steps/FINISH';

const gitInfo = GitInfo();

const DEFAULT_STATE = {
  progress: 1,
  step1: null,
  step2: [],
  step3: [],
  step4: {
    checked: {},
    custom: {}
  },
  step5: {},
  step6: null,
  step7: {},
  revision: gitInfo.commit.hash
};

const useSteps = () => {
  const [value, setValue] = useLocalStorage(STORAGE_KEY, DEFAULT_STATE);

  const reducer = useMemo(() => createReducer({
    [COMPLETE]: (state, { step, data }) => ({
      ...state,
      progress: step + 1,
      [`step${step}`]: data
    }),

    [BACKWARD]: (state, { step }) => ({
      ...state,
      progress: step
    }),

    [FINISH]: (state) => ({
      ...state,
      ...DEFAULT_STATE
    })
  }), []);

  const init = useCallback((storedValue) => {
    // Drop saved local state if specified query parameter
    if (window.location.search.indexOf('clearcache') > -1) {
      return DEFAULT_STATE;
    }

    // Drop saved local state if application was updated
    if (storedValue && (storedValue.revision !== DEFAULT_STATE.revision)) {
      return DEFAULT_STATE;
    }

    return storedValue;
  }, []);

  const [state, dispatch] = useReducer(reducer, value, init);

  useEffect(() => {
    setValue(state);
  }, [state, setValue]);

  const actions = useMemo(() => ({
    complete: (step, data) => dispatch({
      type: COMPLETE,
      step,
      data
    }),

    backward: (step) => dispatch({
      type: BACKWARD,
      step
    }),

    finish: () => dispatch({
      type: FINISH
    })
  }), []);

  return [state, actions];
};

export default useSteps;
